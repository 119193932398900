import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/form-survey',
    name: 'form-survey',
    component: () => import('../views/FormSurvey.vue')
  },
  {
    path: '/survey-guru',
    name: 'survey-guru',
    component: () => import('../views/SurveyGuru.vue')
  },
  {
    path: '/hasil-survey-guru',
    name: 'hasil-survey-guru',
    component: () => import('../views/HasilSurveyGuru.vue')
  },
  {
    path: '/hadir-kajian',
    name: 'hadir-kajian',
    component: () => import('../views/HadirKajian.vue')
  },
  {
    path: '/hasil-kajian-guru',
    name: 'hasil-kajian-guru',
    component: () => import('../views/HasilKajianGuru.vue')
  },
  {
    path: '/hasil-survey-kajian',
    name: 'hasil-survey-kajian',
    component: () => import('../views/HasilSurveyKajian.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/Dashboard.vue'),
    children: [
      {
        path: 'hasil-survey',
        name: 'hasil',
        component: () => import('../components/HasilSurvey.vue'),
      },
      {
        path: 'arsip-survey',
        name: 'arsip',
        component: () => import('../components/ArsipSurvey.vue'),
      },
      {
        path: 'list-karantina',
        name: 'karantina',
        component: () => import('../components/ListKarantina.vue'),
      },
      {
        path: 'hasil-Kajian',
        name: 'hasil-kajian',
        component: () => import('../components/HasilKajian.vue'),
      },
      {
        path: 'arsip-Kajian',
        name: 'arsip-kajian',
        component: () => import('../components/ArsipKajian.vue'),
      },
      {
        path: 'data-pokok',
        name: 'data-pokok',
        component: () => import('../components/DataPokok.vue'),
      },
      {
        path: 'import-data',
        name: 'import-data',
        component: () => import('../components/ImportData.vue'),
      }
    ]
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (process.env.NODE_ENV == 'development') {
    console.log(to.matched[0].components)
  }
  next()
})
export default router

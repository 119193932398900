<template>
  <q-layout>
    <q-page-container>
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script>

export default {
  name: 'App',

  data () {
    return {
      
    }
  }
}
</script>

<style lang="scss">
@import "@/styles/quasar.scss";
.stickyTable {
  height: 400px;

  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th {
    background-color: $indigo-2;
  }
  thead tr th {
    position: sticky;
    z-index: 1;
  }
  thead tr:first-child th {
    top: 0;
  }
  &.q-table--loading thead tr:last-child th {
    top: 48px;
  }
}
</style>
